import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';

function Terms() {
    return (
        <>
            <div className='app-bg'>
                <Header route='methodology' />

                <div className="terms-container">
                    <h1>Terms and Conditions</h1>
                    <section>
                        <p>
                            Welcome to EduPrepMe (eduprepme.com). These Terms and Conditions (“Terms”) govern your use of our services. By accessing or using our Website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Website.
                        </p>
                    </section>

                    <section>
                        <h4>1. Use of Services</h4>
                        <ul>
                            <li>
                                <span>Eligibility:</span> You must be at least 13 years old to use our Services. By using our Website, you represent and warrant that you are at least 13 years old.
                            </li>
                            <li>
                                <span>Account Registration:</span> You may need to create an account to access certain features of our Services. You agree to provide accurate and complete information during registration and keep your account information up-to-date.
                            </li>
                            <li>
                                <span>Personalized SAT Tests:</span> Our AI-powered platform offers personalized SAT tests based on your performance and progress. You understand that the recommendations and personalized tests are generated by automated systems.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>2. User Conduct</h4>
                        <ul>
                            <li>
                                <span>Prohibited Activities:</span> You agree not to use our Services for any unlawful purpose or in a manner that could harm our Website, Services, or other users. Prohibited activities include, but are not limited to:
                                <ul>
                                    <li>
                                        Engaging in any form of harassment, discrimination, or abuse.
                                    </li>
                                    <li>
                                        Attempting to gain unauthorized access to our systems or accounts.
                                    </li>
                                    <li>
                                        Disrupting or interfering with the proper functioning of our Website or Services.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span>User Content:</span> You may have the ability to submit content, such as feedback or comments, to our Website. By submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute your content.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>3. Privacy</h4>
                        <ul>
                            <li>
                                <span>Data Collection:</span> We collect and use personal information in accordance with our Privacy Policy. By using our Services, you consent to our collection and use of your personal information as described in the Privacy Policy.
                            </li>
                            <li>
                                <span>Cookies:</span> Our Website uses cookies to enhance your experience. By using our Website, you consent to the use of cookies.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>4. Intellectual Property</h4>
                        <ul>
                            <li>
                                <span>Ownership:</span> All content, features, and functionality on our Website, including text, graphics, logos, and software, are the exclusive property of EduPrepMe and are protected by intellectual property laws.
                            </li>
                            <li>
                                <span>Limited License:</span> You are granted a limited, non-exclusive, non-transferable license to access and use our Services for personal, non-commercial purposes.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>5. Disclaimers and Limitation of Liability</h4>
                        <ul>
                            <li>
                                <span>Disclaimer of Warranties:</span> Our Services are provided “as is” and “as available” without any warranties, express or implied. We do not guarantee the accuracy, completeness, or reliability of our Services.
                            </li>
                            <li>
                                <span>Limitation of Liability:</span> To the fullest extent permitted by law, EduPrepMe shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Services.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>6. Indemnification</h4>
                        <ul>
                            <li>
                                You agree to indemnify and hold harmless EduPrepMe and its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including reasonable attorney fees, arising out of or in any way connected with your use of our Services or violation of these Terms.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>7. Termination</h4>
                        <ul>
                            <li>
                                We reserve the right to terminate or suspend your account and access to our Services at our sole discretion, without prior notice, for conduct that we believe violates these Terms or is harmful to other users or our Services.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>8. Governing Law</h4>
                        <ul>
                            <li>
                                These Terms are governed by and construed in accordance with the laws of The United States of America, without regard to its conflict of law principles.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>9. Changes to Terms</h4>
                        <ul>
                            <li>
                                We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on our Website. Your continued use of our Services after the changes become effective constitutes your acceptance of the new Terms.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h4>10. Contact Us</h4>
                        <ul>
                            <li>
                                If you have any questions about these Terms, please contact us at <a href="mailto:eduprepme.dsat@gmail.com">eduprepme.dsat@gmail.com</a>.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <p>
                            <section>
                                <p>
                                    Welcome to EduPrepMe (eduprepme.com). These Terms and Conditions (“Terms”) govern your use of our services. By accessing or using our Website, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Website.
                                </p>
                            </section>
                        </p>
                    </section>
                </div>

                <Footer />
            </div>
        </>
    );
}

export default Terms;
