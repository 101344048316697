import React, { useState, useEffect, useRef } from 'react';

const getRemainingTime = (durationInSeconds) => {
  return {
    remainingSeconds: durationInSeconds,
    minutes: Math.floor(durationInSeconds / 60),
    seconds: durationInSeconds % 60
  }
}

const CountdownTimer = ({ durationInMinutes, onTimeUpdate }) => {
  const [timeRemaining, setTimeRemaining] = useState(getRemainingTime(durationInMinutes * 60)); // Initialize with total seconds
  const timerRef = useRef(null)

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeRemaining((prevTimeRemaining) => {
        const newTime = Math.max(prevTimeRemaining.remainingSeconds - 1, 0); // Ensure time doesn't go below zero
        onTimeUpdate(newTime); // Update parent with new time
        return getRemainingTime(newTime)
      });
    }, 1000);

     // Clean up interval on unmount
     return () => {
      clearInterval(timerRef.current)
      timerRef.current = null
    };
  }, []);

  return (
    <div className="timebox text-center">
      {timeRemaining.remainingSeconds > 0 ? `${timeRemaining.minutes} Minutes ${timeRemaining.seconds < 10 ? '0' : ''}${timeRemaining.seconds} Seconds` : 'Time is up!'}
    </div>
  );
};
export default CountdownTimer;
