import React, { useContext, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import faqImg from './assets/faq.jpg'
import Footer from './components/Footer';
import Header from './components/Header';
import { createAllTest, getAllSubjects, getStudentLists, getTestSubitList } from './components/apiCalls';
import { TestContext } from '../src/TextContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { convertIndexToOption, secondsToHms, secondsToMs } from './utils';
import moment from 'moment'
import { durations } from './utils/stubs';

function StudentDashboard() {
  const { setTestData } = useContext(TestContext);
  const [modalShow, setModalShow] = useState({});
  const [questionModalShow, setQuestionModalShow] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDuration, setSelectedDuration] = useState('');
  const [testDetails, setTestDetails] = useState([]);
  const [testResults, setTestResults] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('student-token')))
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isTestAllowed, setIsTestAllowed] = useState(true)
  const [testTimer, setTestTimer] = useState({
    remainingSeconds: 0,
    timeStr: ''
  })
  const testTimerRef = useRef(null)



  useEffect(() => {
    if (!user?.token) {
      navigate('/')
    } else {
      getSubjectsList();
      getTestList();
    }

  }, []);

  useEffect(() => {
    // Check if all necessary conditions are met to enable the button
    if (selectedSubject && selectedDuration && user?.userId) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedSubject, selectedDuration, user]);


  const getSubjectsList = async () => {
    let val = { parentId: 0 };
    try {
      let data = await getAllSubjects(val);
      if (data) {
        setSubjects(data);
      }
    } catch (error) {
      console.log({ form: 'An error occurred. Please try again.' });
    }
  };

  const getTestResults = async (id) => {
    let val = { 'id': id };
    try {
      let data = await getTestSubitList(val);
      if (data) {
        setTestResults(data);
      }
    } catch (error) {
      console.log({ form: 'An error occurred. Please try again.' });
    }
  };

  const createTest = async () => {
    const durationInSeconds = durations.find((dur) => dur.id === selectedDuration)?.value
    if (!durationInSeconds) {
      return
    }
    let val = {
      subjectId: selectedSubject,
      timeDuration: durationInSeconds, // Convert duration to seconds
      userId: user?.userId // Assume 12 is the current user's ID
    };

    try {
      let data = await createAllTest(val);
      if (data && data.status !== false) {
        // Handle success (e.g., show a message, update the UI, etc.)
        setTestData(data);

        // const queryParams = new URLSearchParams({
        //   duration: data.duration,
        //   id: data.id,
        //   noOfQuestion: data.noOfQuestion,
        //   title: data.title,
        //   type: data.type,
        //   userId: data.userId,
        // });

        // Open a new window/tab with the target page and URL parameters
        // window.open(`/questions?${queryParams.toString()}`, '_blank');
        // navigate(`/questions?${queryParams.toString()}`);
        const questionWindow = window.open(`${window.location.origin}/questions?id=${data.id}&duration=${data.duration}&noOfQuestion=${data.noOfQuestion}&title=${data.title}&type=${data.type}&userId=${data.userId}`, '_blank', `toolbar=0,location=0,menubar=0,width=${window.screen.availWidth},height=${window.screen.availHeight}`);
        questionWindow.addEventListener('beforeunload', () => {
          getTestList()
        })
        // questionWindow.onbeforeunload = () => {
        //   debugger
        //   getTestList()
        // }
      }
    } catch (error) {
      console.log({ form: 'An error occurred. Please try again.' });
    }
  };



  const getTestList = async () => {
    try {
      const val = { userId: user?.userId };
      const data = await getStudentLists(val);
      data.sort((a, b) => b.id - a.id)
      data.forEach((item) => {
        if (item.date) {
          item.dateLabel = moment(item.date).format("MM/DD/YYYY")
        }
      })
      if (data.length > 0) {
        setTestDetails(data);
      }
      // if (!JSON.parse(localStorage.getItem('debug-mode'))) {
      //   handleTestAvailability(data)
      // }
    } catch (error) {
      console.log({ form: 'An error occurred. Please try again.' });
    }
  };

  const handleTestAvailability = (data) => {
    const secondsInDay = 86400
    const maxDate = Math.max(...data.map(e => new Date(e.date)))
    const lastDateOfTest = moment(maxDate)
    const secondsAfterLastTest = moment().diff(lastDateOfTest, 's')
    if (isNaN(secondsAfterLastTest) || maxDate === 0) {
      return
    }
    setIsTestAllowed(secondsAfterLastTest >= secondsInDay)
    if (secondsAfterLastTest < secondsInDay) {
      const remainingSeconds = secondsInDay - secondsAfterLastTest
      const timeStr = secondsToHms(remainingSeconds)
      setTestTimer({
        remainingSeconds,
        timeStr,
      })
      if (testTimerRef.current !== null) {
        clearInterval(testTimerRef.current)
        testTimerRef.current = null
      }
      testTimerRef.current = setInterval(() => {
        setTestTimer(({ remainingSeconds }) => {
          if (remainingSeconds > 1) {
            const newRemainingSec = remainingSeconds - 1
            const newTimeStr = secondsToHms(newRemainingSec)
            return {
              remainingSeconds: newRemainingSec,
              timeStr: newTimeStr
            }
          } else {
            setIsTestAllowed(true)
            return {
              remainingSeconds: 0,
              timeStr: ''
            }
          }

        })
      }, 1000);
    }

  }

  const handleViewClick = (result, index) => {
    setSelectedQuestion({ ...result, index });
    setQuestionModalShow(true);
  };

  useEffect(() => {
    return () => {
      if (testTimerRef.current !== null) {
        clearInterval(testTimerRef.current)
        testTimerRef.current = null
      }
    }
  }, [])

  return (
    <div>
      <Header route='student-dashboard' />
      <section className="user-board-panel">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-12 col-12">
              <div className="row mb-5">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedSubject(e.target.value)}
                    value={selectedSubject}
                    disabled={!isTestAllowed}
                  >
                    <option value="">Select Subject</option>

                    {subjects?.length > 0 ?
                      subjects?.map((subject, index) => (
                        <option key={index} value={subject.id}>{subject.name}</option>
                      )) : null}

                  </select>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedDuration(e.target.value)}
                    value={selectedDuration}
                    disabled={!isTestAllowed}
                  >
                    <option value="">Duration</option>
                    {durations.map((duration, index) => (
                      <option key={duration.id} value={duration.id}>{duration.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="actionbox d-grid gap-2 col-9 mx-auto">
                <button className="btn btn-danger btn-lg" onClick={createTest} disabled={isButtonDisabled || !isTestAllowed}>
                  Take Personalized Tests
                </button>
                <div className="test-info-box mt-3 mb-3">
                  {!isTestAllowed && <p className="mb-0 text-center">
                    Next test will be available after:<br /> {testTimer?.timeStr}
                  </p>}
                  {/* <p className="mb-0 text-center">
                    There is a limit to how many tests <br />
                    can be taken within one day
                  </p> */}
                </div>
                {/* <button className="btn btn-lg btn-secondary" disabled>Take Full Length Tests</button> */}
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-12">
              {/* Other content of the component */}
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-12" >
                  <div className="board-info-box fastest dashboard-shortcuts-disable-container" onClick={() => setModalShow({})} style={{ position: 'relative' }}>
                    <div className="board-icon-box">
                      <i color='gray' className="icon-Fastest"></i>
                    </div>
                    <div className="board-label-box">Your Fastest Area</div>
                    <div className='dashboard-shortcuts-disable'></div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <div className="board-info-box slowest dashboard-shortcuts-disable-container" onClick={() => setModalShow({})}>
                    <div className="board-icon-box">
                      <i className="icon-Slowest"></i>
                    </div>
                    <div className="board-label-box">Your Slowest Area</div>
                    <div className='dashboard-shortcuts-disable'></div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <div className="board-info-box weak dashboard-shortcuts-disable-container" onClick={() => setModalShow({})}>
                    <div className="board-icon-box">
                      <i className="icon-Weak"></i>
                    </div>
                    <div className="board-label-box">Your Weak Area</div>
                    <div className='dashboard-shortcuts-disable'></div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                  <div className="board-info-box best dashboard-shortcuts-disable-container" onClick={() => setModalShow({})}>
                    <div className="board-icon-box">
                      <i className="icon-Best"></i>
                    </div>
                    <div className="board-label-box">Your Best Area</div>
                    <div className='dashboard-shortcuts-disable'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 userDataTable">
              <table id="userDataTable" className="table hover cell-border" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    {/* <th>#</th> */}
                    <th>Test Name</th>
                    <th>Date</th>
                    <th>Question</th>
                    <th>Attempt</th>
                    <th>Correct</th>
                    <th>Test Duration</th>
                    <th>Time Taken</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {testDetails?.map((detail, index) => (
                    <tr key={index}>
                      {/* <td>{detail.id}</td> */}
                      <td>{detail.testName}</td>
                      <td>{detail.dateLabel}</td>
                      <td>{detail.noOfQuestions}</td>
                      <td>{detail.attemptedQuestion}</td>
                      <td>{detail.correctQuestion}</td>
                      <td>{detail.testDuration ? secondsToMs(detail.testDuration) : '-'}</td>
                      <td>{detail.timeTaken ? secondsToMs(detail.timeTaken) : '-'}</td>
                      <td><button className="btn btn-outline-secondary" onClick={() => {
                        setModalShow({ ...detail })
                        getTestResults(detail?.id)
                      }}>Analysis</button></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for Analysis */}
      {modalShow?.id && (
        <div className="modal fade show" style={{ display: 'block' }} id="analysis" tabIndex="-1" aria-labelledby="analysis" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <div className="d-inline p-2 bg-primary text-white mx-3">Test</div> {modalShow?.testName}
                </h5>
                <button style={{ marginRight: '10px' }} type="button" className="btn-close" onClick={() => setModalShow({})} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <table id="userDataTable" className="table hover cell-border" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Topic</th>
                      <th>My Answer</th>
                      <th>Right Answer</th>
                      <th>Result</th>
                      <th>Time Taken</th>
                      <th>Difficulty</th>
                      <th>Explanation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResults?.map((result, resultIndex) =>
                      result?.questionList?.map((question) => {
                        const rightAnswerIndex = question?.optionList?.findIndex((item) => item.answer)
                        const myAnswerIndex = question?.optionList?.findIndex((item) => item.yourAnswer)
                        const resultStr = (typeof rightAnswerIndex === 'number' && myAnswerIndex === rightAnswerIndex) ? 'Correct' : 'Incorrect'
                        return (
                          <tr key={`${resultIndex}-${question.id}`}>
                            <td>{resultIndex + 1}</td>
                            <td>{question.title || 'N/A'}</td>
                            {/* <td dangerouslySetInnerHTML={{ __html: question.description }}></td> */}
                            <td>
                              <span>{convertIndexToOption(myAnswerIndex)}</span>
                            </td>
                            <td>
                              <span>{convertIndexToOption(rightAnswerIndex)}</span>
                            </td>
                            <td>
                              <span>{resultStr}</span>
                            </td>
                            <td>{question.timeTaken ? secondsToMs(question.timeTaken) : '-'}</td>
                            <td>{question?.initialLevel}</td>

                            <td>
                              <button className="btn btn-danger" onClick={() => handleViewClick(result, resultIndex + 1)}>View</button>

                            </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </table>


              </div>
              <div className="modal-footer">
                <button type="button" style={{ marginRight: '15px' }} className="btn btn-danger" onClick={() => setModalShow({})}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Question Review */}
      {questionModalShow && selectedQuestion && (
        <div className="modal fade show" style={{ display: 'block' }} id="viewQuestion" tabIndex="-1" aria-labelledby="viewQuestion" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog modal-xl question-review-container">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="viewQuestion">Question Review</h5>
                <button type="button" className="btn-close" onClick={() => setQuestionModalShow(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {selectedQuestion?.questionList.map((question, index) => (
                  <div className="row mb-4" key={question.id}>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-12">
                      <div className="question-detail-number-box">Question: {selectedQuestion.index}</div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-12">
                      <div className="question-detail-text-box">
                        <label className='header-label'>Question: </label>
                        <div className="question-detail-text-short-info"
                          dangerouslySetInnerHTML={{
                            __html: question.description
                              ? `<strong></strong> ${question.description}`
                              : "",
                          }}



                        ></div>
                        <label className='header-label'>Options: </label>
                        <div className="question-detail-list">
                          <ul>
                            {question.optionList.map((option, optionIndex) => (
                              <li key={optionIndex}>
                                <span>{optionIndex + 1}.</span>
                                <div className="question-detail-text-short-info"
                                  dangerouslySetInnerHTML={{
                                    __html: option.options
                                      ? `<strong></strong> ${option.options}`
                                      : "",
                                  }}


                                ></div>

                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="correct-answer-box">
                          <label className='header-label'>Correct Answer: </label>
                          <div className="question-detail-text-full-info"


                            dangerouslySetInnerHTML={{
                              __html: `<strong></strong> ${question.optionList.find(option => option.answer)?.options || 'NA'}`
                            }}


                          ></div>
                          <label className='header-label'>Your Answer: </label>
                          <div className="question-detail-text-full-info"


                            dangerouslySetInnerHTML={{
                              __html: `<strong></strong> ${question.optionList.find(option => option.yourAnswer)?.options || 'NA'}`
                            }}
                          ></div>
                        </div>
                        <label className='header-label'>Solution: </label>
                        <div className="question-detail-text-short-info"


                          dangerouslySetInnerHTML={{
                            __html: question.hint
                              ? `<strong></strong> ${question.hint}`
                              : "",
                          }}


                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={() => setQuestionModalShow(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}



      <Footer />
    </div>
  );
}

export default StudentDashboard;
