import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/logo.png';
import '../App.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="main-footer">
    <div className="container">
      <div className="footer-logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="footer-menu">
        <ul>
          <li><Link to="https://www.eduprepme.com/aboutus">About Us</Link></li>
          <li><Link to="https://www.eduprepme.com/terms">Terms and Conditions</Link></li>
          <li><Link to="https://www.eduprepme.com/privacy">Privacy Policy</Link></li>
        </ul>
      </div>
      <div className="registered-trademark">
        <p>SAT® is a registered trademark of the College Board, which was not involved in the production of, and does not endorse this product.</p>
      </div>
    </div>
  </footer>
  );
};

export default Footer;
