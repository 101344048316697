import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import joinNow from '../src/assets/joinnow.jpeg';
import Header from './components/Header';
import Footer from './components/Footer';
import { getSignup, getSocialLogin } from './components/apiCalls';
import { useNavigate } from 'react-router-dom';
import { actionNotifier } from './components/actionNotice';
import CountryList from 'react-select-country-list';
import Select from 'react-select';
import { Country, State } from 'country-state-city';
import { useGoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik'
import { SignUpSchema } from './validation'
import isEmpty from 'lodash/isEmpty'
import clsx from 'clsx'

const formState = {
  name: '',
  email: '',
  mobile: '',
  password: '',
  type: 0,
  gender: 'Male',
  country: '',
  state: '',
  city: '',
  address: '',
}

function Signup() {
  const [errors, setErrors] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const navigate = useNavigate();

  const signupSubmit = async (values, { resetForm }) => {

    setIsSubmitting(true);

    const userData = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      password: values.password,
      type: values.type,
      gender: values.gender,
      country: values?.country?.label,
      state: values?.state?.label,
      city: values.city,
      address: values.address
    };


    try {
      const response = await getSignup(userData);
      if (response.id) {
        actionNotifier.success('Signup Successfully')
        navigate('/login');
      } else {
        setErrors({ form: response.message });
      }
    } catch (error) {
      setErrors({ form: 'An error occurred. Please try again.' });
    }

    setIsSubmitting(false);
  };

  const formik = useFormik({
    initialValues: formState,
    validationSchema: SignUpSchema,
    onSubmit: signupSubmit,
  });

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsSubmitting(true);
        const payload = {
          accessToken: tokenResponse.access_token
        }
        let response = await getSocialLogin(payload);
        if (response.userId) {
          actionNotifier.success('Signup Successfully')
          navigate('/login');
        } else {
          setErrors({ form: response.message });
        }
      } catch (error) {
        console.error('API Error:', error); // Log the error for debugging purposes
        setErrors({ form: 'An error occurred. Please try again.' });
      } finally {
        setIsSubmitting(false);
      }
    },
    scope: 'profile email', // Requesting access to profile and email
  });

  useEffect(() => {
    // Fetch countries from country-state-city package
    const countries = Country.getAllCountries().map(country => ({
      value: country.isoCode,
      label: country.name
    }));
    setCountryOptions(countries);
  }, []);

  const handleCountryChange = (country) => {
    formik.setFieldValue('country', country);
    // Fetch states for the selected country
    const states = State.getStatesOfCountry(country.value).map(state => ({
      value: state.isoCode,
      label: state.name
    }));
    setStateOptions(states);
  };
  const handleStateChange = (state) => {
    formik.setFieldValue('state', state);
  };



  // Custom styles for the Country dropdown
  const customStylesCountry = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2000, // Ensure this is higher than the State dropdown
    }),
    control: (provided) => ({
      ...provided,
      zIndex: 1, // Lower z-index to stay behind other elements
    }),
  };

  // Custom styles for the State dropdown
  const customStylesState = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1500, // Ensure this is lower than the Country dropdown
    }),
    control: (provided) => ({
      ...provided,
      zIndex: 1, // Lower z-index to stay behind other elements
    }),
  };

  console.log(formik.values)



  return (
    <>
      <div>
        <Header />
        <section className="h-100 h-custom bg-box">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-lg-8 col-xl-6">
                <div className="card rounded-3">
                  <img
                    src={joinNow}
                    className="w-100"
                    style={{ borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}
                    alt="Sample photo"
                  />
                  <div className="card-body p-4 p-md-5">
                    <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">Sign Up with</h3>
                    <div className="d-flex gap-3 flex-column flex-md-row mb-5">
                      <a href="#!" className="btn bsb-btn-xl btn-outline-danger">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          className="bi bi-google" viewBox="0 0 16 16">
                          <path
                            d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                        </svg>
                        <span onClick={onGoogleLogin} className="ms-2 fs-6">Google</span>
                      </a>
                    </div>
                    {/* <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">OR Registration Info</h3> */}
                    <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">Registration Info</h3>
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                      <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
                      {errors.form && <div className="alert alert-danger">{errors.form}</div>}
                      <div className="row gy-3 overflow-hidden">
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
                              id="Name"
                              name="name"
                              placeholder="Name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                            />
                            <label htmlFor="Name" className="form-label">
                              Name
                            </label>
                            <div className="label-info">Please provide full name.</div>
                            {formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">


                            <input
                              className={`form-control ${formik.errors.email ? 'is-invalid' : ''}`}
                              id="email"
                              name="email"
                              placHolder="name@example.com"
                              placeholderTextColor= 'red'
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />

                            <label htmlFor="Email" className="form-label">
                              Email
                            </label>
                            <div className="label-info">e.g. name@example.com</div>
                            {formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-floating mb-3">

                            <input
                              type="password"
                              className={`form-control ${formik.errors.password ? 'is-invalid' : ''}`}
                              id="Password"
                              placeholder="Password"
                              name="password"
                              value={formik.values.password}
                              onChange={formik.handleChange}
                            />
                            <label htmlFor="Password" className="form-label">
                              Password
                            </label>
                            <div className="label-info">Password should be alphanumeric, minimum 6 characters long.</div>
                            {formik.errors.password && <div className="invalid-feedback">{formik.errors.password}</div>}
                          </div>
                        </div>
                        <br />

                        <div className="col-12 mb-3">
                          <h6 className="mb-2 pb-1">Gender: </h6>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="femaleGender"
                              value="Female"
                              checked={formik.values.gender === 'Female'}
                              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="femaleGender">
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="maleGender"
                              value="Male"
                              checked={formik.values.gender === 'Male'}
                              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="maleGender">
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="otherGender"
                              value="Other"
                              checked={formik.values.gender === 'Other'}
                              onChange={(e) => formik.setFieldValue('gender', e.target.value)}
                            />
                            <label className="form-check-label" htmlFor="otherGender">
                              Other
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <label htmlFor="country" className="form-label">Select Country:</label>
                          <Select
                            id="country"
                            value={formik.values.country}
                            name="country"
                            onChange={handleCountryChange}
                            options={CountryList().getData()}
                            placeholder="Select Country"
                            styles={customStylesCountry}
                            className={`form-control ${formik.errors.country ? 'is-invalid' : ''}`}
                          />
                          {formik.errors.country && <div className={clsx({ "invalid-feedback": true, "d-block": formik.errors.country })}>{formik.errors.country}</div>}
                        </div>
                        <div className="col-12">
                          <label htmlFor="state" className="form-label">Select State:</label>
                          <Select
                            id="state"
                            value={formik.values.state}
                            name="state"
                            onChange={handleStateChange}
                            options={stateOptions}
                            placeholder="Select State"
                            styles={customStylesState}
                            className={`form-control ${formik.errors.state ? 'is-invalid' : ''}`}
                          />
                          {formik.errors.state && <div className={clsx({ "invalid-feedback": true, "d-block": formik.errors.state })}>{formik.errors.state}</div>}
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className={`form-control ${formik.errors.city ? 'is-invalid' : ''}`}
                              id="city"
                              name="city"
                              placeholder="City"
                              value={formik.values.city}
                              onChange={formik.handleChange}
                            />
                            <label htmlFor="city" className="form-label">
                              City
                            </label>
                            {formik.errors.city && <div className="invalid-feedback">{formik.errors.city}</div>}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-check mt-3">
                            <input className="form-check-input" type="checkbox" id="terms" required value={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked)} />
                            <label className="form-check-label" htmlFor="terms">
                              Agree to our terms and conditions/privacy policy
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button className="btn btn-dark btn-lg" type="submit" disabled={!isEmpty(formik.errors) || !agreeToTerms || isSubmitting}>
                              {isSubmitting ? 'Signing up...' : 'JOIN NOW'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="backgrond-img-box position-absolute">
            <img src="./img/background-img-view.jpeg" alt="" />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Signup;
