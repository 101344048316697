import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import { Link } from 'react-router-dom';
import logo from './assets/logo.png'
import Header from './components/Header';
import Footer from './components/Footer';
import banner from './assets/banner-object.png'
import { addContact } from './components/apiCalls';
import { actionNotifier } from './components/actionNotice';
import { testimonials } from './utils/stubs';
import { createDynamicCounts } from './utils';

const REGISTERED_USER_BASE = 2000
const REGISTERED_USER_COUNT = 1
const REGISTERED_USER_FREQUENCY = 25*60

const TEST_TAKEN_BASE = 4000
const TEST_TAKEN_COUNT = 1
const TEST_TAKEN_FREQUENCY = 11*60

const QUESTION_SOLVED_BASE = 10000
const QUESTION_SOLVED_COUNT = 15
const QUESTION_SOLVED_FREQUENCY = 20*60


function MainPage() {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const registeredUsersCountTimer = useRef(null)
  const testsTakenCountTimer = useRef(null)
  const questionsSolvedCountTimer = useRef(null)
  const [registeredUsersCount, setRegisteredUsersCount] = useState(createDynamicCounts(REGISTERED_USER_BASE, REGISTERED_USER_FREQUENCY, REGISTERED_USER_COUNT))
  const [testsTakenCount, setTestsTakenCount] = useState(createDynamicCounts(TEST_TAKEN_BASE, TEST_TAKEN_FREQUENCY, TEST_TAKEN_COUNT))
  const [questionsSolvedCount, setQuestionsSolvedCount] = useState(createDynamicCounts(QUESTION_SOLVED_BASE, QUESTION_SOLVED_FREQUENCY, QUESTION_SOLVED_COUNT))

  const submitForm = async (e) => {
    e.preventDefault(); // Prevents default form submission

    setLoading(true); // Start loading

    try {
      const data = await addContact(formData); // Call your API function with formData
      if (data?.id) {
        // Handle success, e.g., show success message
        actionNotifier.success('Contact form submitted successfully');
        setSubmitted(true);
        setFormData({ email: '', subject: '', message: '' }); // Clear form fields
      } else {
        // Handle failure, e.g., show error message
        console.error('Failed to submit contact form');
      }
    } catch (error) {
      console.error('Error submitting contact form:', error);
      // Handle error, show error message or log to console
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('student-token');
    setIsLoggedIn(!!token); // Simplified setting of isLoggedIn
  }, []);

  useEffect(() => {
    registeredUsersCountTimer.current = setInterval(() => {
      setRegisteredUsersCount((createDynamicCounts(REGISTERED_USER_BASE, REGISTERED_USER_FREQUENCY, REGISTERED_USER_COUNT)))
    }, REGISTERED_USER_FREQUENCY * 1000);
    testsTakenCountTimer.current = setInterval(() => {
      setTestsTakenCount(createDynamicCounts(TEST_TAKEN_BASE, TEST_TAKEN_FREQUENCY, TEST_TAKEN_COUNT))
    }, TEST_TAKEN_FREQUENCY * 1000);
    questionsSolvedCountTimer.current = setInterval(() => {
      setQuestionsSolvedCount(createDynamicCounts(QUESTION_SOLVED_BASE, QUESTION_SOLVED_FREQUENCY, QUESTION_SOLVED_COUNT))
    }, QUESTION_SOLVED_FREQUENCY * 1000);
    return () => {
      clearInterval(registeredUsersCountTimer.current)
      clearInterval(testsTakenCountTimer.current)
      clearInterval(questionsSolvedCountTimer.current)
    }
  }, [])

  return (
    <>
      <div>

        <Header />

        <section className="header-banner">
          <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 info-part">
                      <div className="banner-title">Democratizing the D-SAT</div>
                      <div className="banner-subtitle">
                        With FREE Access of <br /><span>Unlimited Personalized Tests</span>
                      </div>
                      <Link to={isLoggedIn ? "/student/dashboard" : "/login" }className="btn btn-danger btn-lg">
                        Get Started for Free
                      </Link>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12 img-part">
                      <img src={banner} alt="banner object" className="img-fluid" />
                      <div className="eduprepme-user-info">
                        <div className="eduprepme-registers-Users">
                          <div className="iconbox registers-Users">
                            <i className="icon-desktop-computer"></i>
                          </div>
                          <div className="text-box">
                            <div className="user-title"> Registered Users</div>
                            <div className="user-numbers" id="userNumbersbox">{registeredUsersCount}</div>
                          </div>
                        </div>
                        <div className="eduprepme-registers-Users">
                          <div className="iconbox  tests-taken">
                            <i className="icon-test"></i>
                          </div>
                          <div className="text-box">
                            <div className="user-title">Tests Taken</div>
                            <div className="user-numbers" id="testsTaken">{testsTakenCount}</div>
                          </div>
                        </div>
                        <div className="eduprepme-registers-Users">
                          <div className="iconbox questions-taken">
                            <i className="icon-question-1"></i>
                          </div>
                          <div className="text-box">
                            <div className="user-title">Questions Solved</div>
                            <div className="user-numbers" id="questionsSolved">{questionsSolvedCount}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="discover">
          <div className="container" id="methodology">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 discover-info">
                <div className="discover-heading">
                  Why EduPrepMe? <br />
                  Why should you choose
                  <span> EduPrepMe?</span>
                </div>
                <Link to="/methodology" className="btn btn-danger btn-lg">Our Methodology</Link>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 discover-services">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                    <div className="discover-detail-box">
                      <div className="discover-icon">
                        <i className="icon-books-stack-of-three"></i>
                      </div>
                      <div className="discover-text">Adaptive practice tests</div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                    <div className="discover-detail-box">
                      <div className="discover-icon">
                        <i className="icon-leaderboard"></i>
                      </div>
                      <div className="discover-text">Prepare at no cost</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                    <div className="discover-detail-box">
                      <div className="discover-icon">
                        <i className="icon-rating"></i>
                      </div>
                      <div className="discover-text">Improve your score</div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                    <div className="discover-detail-box">
                      <div className="discover-icon">
                        <i className="icon-company-vision"></i>
                      </div>
                      <div className="discover-text">Take unlimited tests</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="why-choose-us">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 testimonials-box">
                <div className="choose-heading">Testimonials</div>
                <div className="row">
                  {testimonials.map((testimonial, index) => (<div key={index} className="col-xl-4 col-lg-4 col-md-4 col-12">
                    <div className="testimonials-detail-row">

                      <div className="testimonials-details">
                        <div className="testimonials-message">
                          {testimonial.content}
                        </div>
                        <div className="testimonials-admin-name">
                        {testimonial.writer}
                        </div>
                      </div>
                    </div>
                  </div>))}
                </div>

              </div>
            </div>

          </div>
        </section>

        <section className="contects" id="contectForm">
          <div className="container">
            <div className="row ">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 contects-form-box">
                <div className="contects-heading">CONTACT US</div>

                <form className="form" onSubmit={submitForm}>
                  <div className="row g-3 align-items-center">
                    <div className="col-auto label">
                      <label htmlFor="inputEmail" className="col-form-label">Email</label>
                    </div>
                    <div className="col-auto fill-restpart">
                      <input
                        type="email"
                        id="inputEmail"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="row g-3 align-items-center">
                    <div className="col-auto label">
                      <label htmlFor="inputSubject" className="col-form-label">Subject</label>
                    </div>
                    <div className="col-auto fill-restpart">
                      <input
                        type="text"
                        id="inputSubject"
                        className="form-control"
                        value={formData.subject}
                        onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="row g-3 align-items-cente">
                  <div className="col-auto label">
                    <label htmlFor="inputSubject" className="col-form-label">Message</label>
                  </div>
                  <div className="col-auto fill-restpart">
                  <textarea
                      className="form-control"
                      id="inputMessage"
                      rows="3"
                      value={formData.message}
                      onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    ></textarea>
                  </div>
                  </div>
                  <div className="row g-3 align-items-center">
                    {/* Conditional rendering of spinner */}
                    <button type="submit" className="btn btn-danger">
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      ) : (
                        'Submit'
                      )}
                    </button>
                  </div>
                </form>
                {submitted && (
                  <div className="alert alert-success mt-3" role="alert">
                    Your query has been submitted successfully!
                  </div>
                )}

              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 eduprepme-club-box">
                <div className="text-center mb-5"><img src="./img/logo.png" alt="" /></div>

                <div className="club-details">
                  <div className="text">
                  EduPrepMe is committed to ensuring that SAT preparation is freely accessible to everyone. Our goal is to eliminate barriers to quality digital testing resources and support students from all backgrounds in their test preparation journey, making effective and comprehensive materials available at no cost to all.
                  </div>
                  {/* <ul className="social-media-box  mt-5 mt-5">
                    <li>
                      <a href="#">
                        <i className="icon-instagram"><span className="path1"></span><span className="path2"></span><span
                          className="path3"></span></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                    <li>
                      <a href="#"><i className="icon-facebook"><span className="path1"></span><span className="path2"></span><span
                        className="path3"></span><span className="path4"></span><span className="path5"></span><span
                          className="path6"></span></i></a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="icon-youtube"><span className="path1"></span><span className="path2"></span><span
                          className="path3"></span><span className="path4"></span><span className="path5"></span><span
                            className="path6"></span><span className="path7"></span></i>
                      </a>
                    </li>
                  </ul> */}
                </div>

              </div>

            </div>
          </div>
        </section>
        <Footer />





      </div>
    </>
  );
}

export default MainPage;

