import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { getLogin, getSocialLogin } from './components/apiCalls';
import { actionNotifier } from './components/actionNotice';
import { useGoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik'
import { SignInSchema } from './validation';

const formState = {
  email: '',
  password: '',
}

function Login() {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const payload = {
          accessToken: tokenResponse.access_token
        }
        let data = await getSocialLogin(payload);
        console.log('API Response:', data); // Log the response from the API
        if (!data?.token) {
          setErrors({ form: 'Invalid email or password.' });
        }
        if (data?.role !== 'USER') {
          setErrors({ form: 'Please login with valid user.' });
        }
        localStorage.setItem('student-token', JSON.stringify(data));
        setLoggedIn(true);
        actionNotifier.success('Logged In Successfully');
        navigate('/student/dashboard');
      } catch (error) {
        console.error('API Error:', error); // Log the error for debugging purposes
        setErrors({ form: 'An error occurred. Please try again.' });
      }
      setIsSubmitting(false);
    },
    scope: 'profile email', // Requesting access to profile and email
  });

  const loginSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    let val = {
      email: values.email,
      password: values.password
    };

    try {
      let data = await getLogin(val);
      console.log('API Response:', data); // Log the response from the API
      if (!data?.token) {
        return setErrors({ form: 'Invalid email or password.' });
      }
      if (data?.role !== 'USER') {
        return setErrors({ form: 'Please login with valid user.' });
      }
      localStorage.setItem('student-token', JSON.stringify(data));
      setLoggedIn(true);
      actionNotifier.success('Logged In Successfully');
      navigate('/student/dashboard');
    } catch (error) {
      console.error('API Error:', error); // Log the error for debugging purposes
      setErrors({ form: 'An error occurred. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: formState,
    validationSchema: SignInSchema,
    onSubmit: loginSubmit,
  });

  return (
    <>
      <Header route="home" />
      <section className="background-radial-gradient overflow-hidden">
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1 className="my-3 display-5 fw-bold ls-tight login-title">
                Welcome to <span>EduPrepMe</span>
              </h1>
              <h5 className="mb-4 opacity-70 login-detail">
                To get started with your adaptive SAT practice, log in to your account, or sign up if you don’t have one
              </h5>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
              <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={formik.handleSubmit}>
                    {errors.form && <div className="alert alert-danger">{errors.form}</div>}

                    <div data-mdb-input-init className="form-outline mb-4">
                      <label className="form-label" htmlFor="form3Example3">Email address</label>
                      <input
                        name="email"
                        id="form3Example3"
                        className={`form-control ${formik.errors.email ? 'is-invalid' : ''}`}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <div className="label-info">e.g. name@example.com</div>
                      {formik.errors.email && <div className="invalid-feedback">{formik.errors.email}</div>}
                    </div>

                    <div data-mdb-input-init className="form-outline mb-4">
                      <label className="form-label" htmlFor="form3Example4">Password</label>
                      <input
                        type="password"
                        className={`form-control ${formik.errors.password ? 'is-invalid' : ''}`}
                        id="Password"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                      <div className="label-info">Password should be alphanumeric, minimum 6 characters long.</div>
                      {formik.errors.password && <div className="invalid-feedback">{formik.errors.password}</div>}
                    </div>

                    <div className="actionbox d-grid gap-2 col-6 mx-auto">
                      <button type="submit" className="btn btn-danger" disabled={isSubmitting}>
                        {isSubmitting ? 'Signing in...' : 'SIGN IN'}
                      </button>
                    </div>
                    <div className="text-center pt-1 mb-1 mt-1 pb-1">
                      <a className="text-muted" onClick={() => {
                        navigate('/forgot-password')
                      }}>
                        Forgot password?
                      </a>
                    </div>

                    <div className="text-center">
                      <h6 className="mb-4 pb-2 pb-md-0 mb-md-3 mt-md-3 px-md-2">
                        Don't have an account?
                        <a onClick={() => {
                          navigate('/signup')
                        }} className="text-danger"> Register</a>
                      </h6>
                      <div className="divider-form my-4">
                        <p className="text-center fw-bold mb-0">Or</p>
                      </div>
                      <div className="row social-login-box">
                        <div className="col-12">
                          <h6 className="mb-4 pb-2 pb-md-0 mb-md-3 mt-md-3 px-md-2">
                            Sign Up with:
                            <a href="#!" className="btn bsb-btn-xl btn-outline-danger mx-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="bi bi-google" viewBox="0 0 16 16">
                                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                              </svg>
                              <span onClick={onGoogleLogin} className="ms-2 fs-6">Google</span>
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Login;
